import $ from 'jquery';

export default function prepareTitle(tabId,label) {
    function onKeyDown(event){
        if (event.keyCode === 13) {
            event.preventDefault()
            document.activeElement.click();
        }
    }
    return <div tabIndex={tabId} onKeyDown={onKeyDown.bind(this)}>{label}</div>
}

export function loaderFocus(className) {
   // The below code is commented due to breaking few other fixes.
    // setTimeout(function(){
    //     let ele = $(className);
    //     ele && ele.attr("tabindex", -1).focus();
    //     }, 10)
    let LoadingElement = document.createElement("span");
    $(LoadingElement).addClass('visually-hidden').attr('id', 'loading-hidden-text').attr('aria-live', 'assertive');
    setTimeout(function () {
        $(LoadingElement).text('Loading...');
    }, 100)
    $('body').append(LoadingElement);

    setTimeout(function () {
        $("#loading-hidden-text").remove();
    }, 800)
}

export function checkBoxStatus(tableId){
    let checkBoxElementTd = $(tableId+" table td input[type='checkbox']");
    let checkBoxElementTh = $(tableId+" table th input[type='checkbox']");
    checkBoxElementTd.change(function () {
        $(this).parent('.ant-checkbox').hasClass('ant-checkbox-checked') ?
            $(this).attr('aria-checked', 'true') :
            $(this).attr('aria-checked', 'false')
    });
    checkBoxElementTh.change(function () {
        checkBoxElementTh.parent('.ant-checkbox').hasClass('ant-checkbox-checked') ?
            checkBoxElementTd.attr('aria-checked', 'true') :
            checkBoxElementTd.attr('aria-checked', 'false')
    });  
    setTimeout(() => {
        checkBoxElementTh.parent('.ant-checkbox').hasClass('ant-checkbox-indeterminate') ?
            checkBoxElementTh.attr('aria-checked', 'mixed') : checkBoxElementTh.removeAttr('aria-checked')
    }, 500)
}

export function checkBoxStatusForCards(tableId){
    let checkBoxElementTd = $(tableId+" table td input[type='checkbox']");
    let checkBoxElementTh = $(tableId+" table th input[type='checkbox']");
    checkBoxElementTd.change(function () {
        $(this).parent('.ant-checkbox').hasClass('ant-checkbox-checked') ?
            $(this).attr('aria-checked', 'false') :
             $(this).attr('aria-checked', 'true')
    });
    checkBoxElementTh.change(function () {
        checkBoxElementTh.parent('.ant-checkbox').hasClass('ant-checkbox-checked') ?
            checkBoxElementTd.attr('aria-checked', 'false') :
            checkBoxElementTd.attr('aria-checked', 'true')
    });
    setTimeout(() => {
        checkBoxElementTh.parent('.ant-checkbox').hasClass('ant-checkbox-indeterminate') ?
            checkBoxElementTh.attr('aria-checked', 'mixed') : checkBoxElementTh.removeAttr('aria-checked')
    }, 500)
}

export function getBrowserName() {
    console.log('In getBrowserName() to print navigator Object',navigator)
    let browserInfo = navigator.userAgent;
    let browser;
    if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
        browser = 'Opera';
    } else if (browserInfo.includes('Edg')) {
        browser = 'Edge';
    } else if (browserInfo.includes('Chrome')) {
        browser = 'Chrome';
    } else if (browserInfo.includes('Safari')) {
        browser = 'Safari';
    } else if (browserInfo.includes('Firefox')) {
        browser = 'Firefox'
    } else {
        browser = 'unknown'
    }
    return browser;
}