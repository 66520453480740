import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import qs from 'qs'
import $ from 'jquery'
import { Layout, Row, Col,  Icon,  Dropdown, notification, Select } from 'antd'
import 'antd/dist/antd.css'
import{ MenuItem, Menu, Button} from '@mui/material';
import LanguageSelectBtn from './LanguageSelect';
// import '../../styling/provisioner.css'
import DashboardCardComponent from './DashboardCard'
import BulkUploadComponent from '../bulk/bulkUpload';
import MyApproval from '../approval/myApproval'
import AllActiveCards from '../allActiveCards';
import AllHistoryCards from '../allHistoryCards';
import ActiveCards from './ActiveCards'
import { logOut } from '../../actions/logoutactions'
import { setTheme } from '../../actions/authenticationActions'
import history from '../history'
import { viewPhoneNumber } from './phoneNumberFormat'
import MyRequestComponent from "./MyRequestComponent";
import ArrowBack from '../../images/bulk/arrowBack.svg';
import UserLogoutPopover from './UserLogoutPopover';
import { getBrowserName } from '../../utils/accessability';

const { Option } = Select;
const { Content } = Layout
const { URLs, sortParam } = require('../../data/constants.js')


class DashboardComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedLang: 'EN',
            title: 'Dashboard',
            visible: false,
            name: '',
            isBack: false,
            backBtn: false,
            owns: false,
            toMyRequest: false,
            anchorEl: false,
            currentTab: 'dashboard'
        }
        this.logoutBtnRef = React.createRef();

    }

    handleDropdownClick = (event) => {
        this.setState({ anchorEl: !this.state.anchorEl });
    };

    handleIsBack = (bool) => {
        this.setState({ isBack: bool })
        this.setState({ backBtn: bool })
    }

    handleBackBtn = (bool) => {
        this.setState({ backBtn: bool || true })
    }

    goToMyRequest = () => {
        this.setState({ toMyRequest: false })
    }

    /**life cycle method (will be called on mount of this component)**/
    componentDidMount() {
        if (window.location.hostname.includes('elan-solutions.elaneasypay')){
            $('.page-title').text("Dashboard: Elan Easy Pay").focus();
            document.title = "Dashboard: Elan Easy Pay";
        }
        else{
            $('.page-title').text("Dashboard: US Bank Instant Card").focus();
            document.title = "Dashboard: US Bank Instant Card";
        }
        axios.get(URLs.comUrl + 'getUserPreference', {
            headers: {
                "Authorization": 'Bearer ' + this.props.token.access_token
            }
        }).then(res => {
            res.data.data &&
                this.setState({
                    selectedLang: res.data.data.LANG_CODE,
                    title: this.props.label_keys[res.data.data.LANG_CODE].dashboard
                }, () => this.updatePendoOptions())
        }).catch(err => notification.error({
                message: <span role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                duration: 3,
                style: {
                    backgroundColor: '#fff1f0',
                    border: "1px solid #ffa39e",
                }
        }))

        const icon = document.querySelectorAll('.anticon.anticon-down.ant-select-arrow-icon');
        if (icon) {
            icon.forEach(el => {
                el.setAttribute('role', 'img')
                el.setAttribute('aria-hidden', 'true')
            })
        }
        const dropDown = document.querySelectorAll('.ant-select-selection.ant-select-selection--single');
        if (dropDown[0]) {
            dropDown.forEach(div => {
                // if (!div.getAttribute('aria-label'))

                div.setAttribute('role', 'menu')
                // div.setAttribute('aria-haspopup', 'listbox')
                // div.setAttribute('aria-labelledby', 'combo1-label')
                div.removeAttribute('aria-autocomplete')
                div.addEventListener('keyup', function () {
                    
                    let optionDiv = document.querySelectorAll('.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active');
                    // console.log("Div:::", optionDiv);
                    if (optionDiv[0]) {
                        let activeDescendant = optionDiv[0].getAttribute('id')
                        //  console.log("active descendant::", activeDescendant);   
                        div.setAttribute('aria-activedescendant', activeDescendant);
                    }
                  });
                // div.addEventListener('keyup', function () {
                    // console.log("Event on click fired");
                    
                    let divDropdown = document.querySelectorAll('.ant-select-dropdown-menu.ant-select-dropdown-menu-root.ant-select-dropdown-menu-vertical');
                    // console.log('divDropdown', divDropdown)
                    if (divDropdown) {
                        divDropdown.forEach(el => {
                            el.setAttribute('aria-labelledby', 'combo1-label')
                            // el.setAttribute('aria-hidden', 'true')
                        })
                    }
                    let divLabel = document.querySelectorAll('.ant-select-selection-selected-value')

                    // console.log('divLabel:::', divLabel);
                    if (divLabel) {
                        divLabel.forEach(el => {
                            const titleValue = el.getAttribute('title')
                            // console.log("titleValue.includes('Active&nbsp;Cards')", titleValue === 'Active Cards', titleValue)
                            if (titleValue === 'Active Cards') {
                                el.setAttribute('id', 'combo1-label')
                                el.setAttribute('aria-hidden', 'true');
                            }
                            // el.setAttribute('aria-hidden', 'true')
                        })
                    }
                });
            // })

        }

    }

    updatePendoOptions = () =>{
        if(window.location.hostname.includes('elan-solutions.elaneasypay'))
            console.log("Elan Easy Pay");
        else {
            window.pendo.updateOptions({
                visitor: {
                    id: this.props.userDetails.userId, 
                    roleId: this.props.userDetails.roles,
                    city: this.props.userDetails.companyAddress && this.props.userDetails.companyAddress.city ? this.props.userDetails.companyAddress.city : null ,
                    state: this.props.userDetails.companyAddress && this.props.userDetails.companyAddress.state ? this.props.userDetails.companyAddress.state : null,
                    country: this.props.userDetails.companyAddress && this.props.userDetails.companyAddress.country ? this.props.userDetails.companyAddress.country : null,
                    lang: this.state.selectedLang,
                    firstName: this.props.userDetails.firstName,
                    email: this.props.userDetails.email,
                    browserPreferredLanguage: navigator.language,
                    browser:  getBrowserName(),
                    companyName: this.props.userDetails.companyName ? this.props.userDetails.companyName : null,
                    companyosn:this.props.userDetails.osn?this.props.userDetails.osn:null
                },
                account: {
                    acctID: this.props.userDetails.companyName ? this.props.userDetails.companyName : null,
                    city: this.props.userDetails.companyAddress && this.props.userDetails.companyAddress.city ? this.props.userDetails.companyAddress.city : null ,
                    state: this.props.userDetails.companyAddress && this.props.userDetails.companyAddress.state ? this.props.userDetails.companyAddress.state : null,
                    country: this.props.userDetails.companyAddress && this.props.userDetails.companyAddress.country ? this.props.userDetails.companyAddress.country : null,
                    osn: this.props.userDetails.osn ? this.props.userDetails.osn : null

                }
            })

            window.pendo.location.addTransforms([{
                'attr': 'search',
                'action': 'ExcludeKeys',
                'data': function(hostname, url) { 
                    return ['type', 'access_token']; 
                }
            }]);
        }
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault()
            document.activeElement.click();
        }

        // console.log("EVENT :::", event);

        if (this.state.visible) {
            if (event.key === "Tab") {
                if (event.shiftKey) {
                    if (document.activeElement === document.getElementById("profile-btn")) {
                        this.setState({ visible: !this.state.visible });
                    } else {
                        setTimeout(() => {
                            document.getElementById("profile-btn").focus();
                        }, 1)

                    }
                } else {
                    if (this.logoutBtnRef.current) {
                        event.preventDefault();
                        setTimeout(() => {
                            this.logoutBtnRef.current.focus();
                        }, 1)
                    }
                }
            }
        }
        // console.log("Event:::: ",this.state.visible, event);
        if (event.key === "Escape") {
            this.setState({ visible: !this.state.visible });

            document.getElementById("profile-btn").focus();
        }


    }

    handleVisibleChange = () => {
        this.setState({ visible: !this.state.visible });
    }

    tabsClick(params, name) {
        if (this.state.backBtn) {
            this.setState({ isBack: true })
            return;
        }
        if (window.location.hostname.includes('elan-solutions.elaneasypay'))
            document.title = "Dashboard: Elan Easy Pay";
        else
            document.title = "Dashboard: US Bank Instant Card";
        
        let tabSelected = params.includes(this.props.label_keys[this.state.selectedLang].request) ? 'request' : Object.keys(this.props.label_keys[this.state.selectedLang]).find(key => this.props.label_keys[this.state.selectedLang][key] === params);

        this.setState({ title: params, name: name, currentTab: tabSelected })
        $('.focus-heading').attr('tabIndex','-1').focus();    
    }

    languageChange = (event) => {
        if (this.state.selectedLang !== event.target.textContent) {
            let obj = { language: event.target.textContent }
            axios.post(URLs.comUrl + 'updateUserPreference', qs.stringify(obj), {
                headers: {
                    "Authorization": 'Bearer ' + this.props.token.access_token
                }
            }).then(res => {
                let selectedTab = this.state.title.includes(this.props.label_keys[this.state.selectedLang].request) ? this.state.title.replace(this.props.label_keys[this.state.selectedLang].request, this.props.label_keys[event.target.textContent].request ) : this.props.label_keys[event.target.textContent][this.state.currentTab]
                this.setState({ selectedLang: event.target.textContent, title: selectedTab }, () => this.updatePendoOptions());
                notification.success({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true" ><span className="visually-hidden">Success </span>{this.props.label_keys[this.state.selectedLang].languageChangeSuccess}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#dff0d8',
                        color: "green",
                        border: "1px solid green",
                    }
                });

            })
                .catch(err => notification.error({
                    message: <span role='alert' aria-live="assertive" aria-atomic="true"><span className="visually-hidden">Error </span>{this.props.label_keys.couldNotcompleteReq}</span>,
                    duration: 3,
                    style: {
                        backgroundColor: '#fff1f0',
                        border: "1px solid #ffa39e",
                    }
                }))
        }
    }

    onChangeActiveCard = (event) => {
        console.log("Value :", event.target.textContent, event);
        if( event.target.textContent === this.props.label_keys[this.state.selectedLang].myActiveCards)
            this.tabsClick(this.props.label_keys[this.state.selectedLang].myActiveCards)
        else if(event.target.textContent === this.props.label_keys[this.state.selectedLang].allActiveCards){
            this.tabsClick(this.props.label_keys[this.state.selectedLang].allActiveCards)  
        } else {
            // All card histroy tab hidden
            //this.tabsClick(this.props.label_keys[this.state.selectedLang].allHistoryCards);
            // this.tabsClick("All Card History")
        }
    }
    
    onLogoutClick = () => {
        sessionStorage.clear()
        this.props.logOutFun()
        history.push('/login')
    }

    render() {
        const open = new Boolean(this.state.anchorEl);
        // const menuLogout = (
        //     <Menu role="presentation" id="menuLogout-div" className="profile-dropdown" >
        //         <div id="menuLogout-div" >
        //             <div aria-hidden="true" className="profile-icon" role="img"><Icon type="user" /></div>
        //             <div  ><b>{this.props.userDetails.firstName} {this.props.userDetails.lastName}</b></div>
        //             <div><span >{this.props.userDetails.email}</span></div>
        //             <div><span  >{viewPhoneNumber(this.props.userDetails.phoneNo)}</span></div>
        //             <div><button id="logout-btn" className="ant-btn ant-btn-background-ghost" ref={this.logoutBtnRef}
        //                 onKeyDown={this.onKeyDown.bind(this)}
        //                 onFocus={() => this.setState({ owns: false })}
        //                 onClick={() => {
        //                     sessionStorage.clear()
        //                     this.props.logOutFun()
        //                     history.push('/login')
        //                 }}>{this.props.label_keys[this.state.selectedLang].logout}</button>
        //                 <button aria-hidden="true" className="visually-hidden">
        //                 </button>
        //             </div>
        //         </div>
        //     </Menu>
        // )

        return (
            <Layout>
                <Content role='presentation' style={{ backgroundColor: 'white' }}>
                    <div className={this.props.theme == 'elan' ? 'container-header-elan' : 'container-header'} >
                        <span class='visually-hidden page-title' tabIndex={-1}></span>
                        <div  role="banner"><Row  gutter={16} className='header-com'>
                            <Col xs={16} sm={16} md={6} lg={6} xl={6} xxl={5} style={{ zIndex: this.state.selectedLang === 'FR' ? '2' : null }}>
                                <span role={this.state.title === this.props.label_keys[this.state.selectedLang].dashboard ? null : "link"}
                                    aria-label={this.state.title === this.props.label_keys[this.state.selectedLang].dashboard ? null : (this.props.theme == 'elan' ? "Elan Easy Pay" : "US Bank Instant Card")}
                                    onClick={() => this.tabsClick(this.props.label_keys[this.state.selectedLang].dashboard)}
                                    tabIndex={this.state.title === this.props.label_keys[this.state.selectedLang].dashboard ? null : 0} onKeyUp={e => {
                                        if (e.keyCode === 13) {
                                            this.tabsClick(this.props.label_keys[this.state.selectedLang].dashboard)
                                        }
                                    }} style={{ cursor: this.state.title === this.props.label_keys[this.state.selectedLang].dashboard ? 'auto' : 'pointer' }}>
                                    {this.props.theme == 'elan' ?
                                        <><img src={require(`../../images/provisioner/${this.props.theme}/logo.svg`).default} height="25px" alt='Elan' />&nbsp;<span className={this.props.theme == 'elan' ? 'instant-card-elan' : 'instant-card'}>|&nbsp;&nbsp;{this.props.label_keys[this.state.selectedLang].instantCard}</span></>
                                        : <><img src={require(`../../images/provisioner/${this.props.theme}/logo.svg`).default} height="25px" alt='US Bank logo' />&nbsp;&nbsp;&nbsp;<span className={this.props.theme == 'elan' ? 'instant-card-elan' : 'instant-card'}>|&nbsp;&nbsp;{this.props.label_keys[this.state.selectedLang].instantCard}</span></>}
                                </span>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="menu-header" gutter={16}>
                                <nav>
                                    <Row gutter={[16, {xs: 6, sm: 6, lg: 12, xl: 12, xxl: 12}]} className="dashboard-tabs-fr" role="menubar" type="flex" justify="end" style={{ marginLeft: this.state.selectedLang === 'FR' ? '-30%' : null }}>
                                        <Col  role="none" xs={12} sm={12} md={6} lg={this.state.selectedLang === 'FR' ? 6 :4} xl={this.state.selectedLang === 'FR' ? 6 : 4} xxl={4}
                                        style={{marginRight: (this.props.userDetails.roles.includes("Provisioner") && this.props.userDetails.roles.includes("Approver") && this.state.selectedLang === 'FR') ? '-9%' : (this.state.selectedLang === 'FR' ? '-7%' : null)}} >
                                            <span role="menuitem" className={this.state.title === this.props.label_keys[this.state.selectedLang].dashboard ? 'active dashboard-none' : 'dashboard-none'} 
                                            aria-current={this.state.title === this.props.label_keys[this.state.selectedLang].dashboard ? 'page' : null}
                                            onClick={() => this.tabsClick(this.props.label_keys[this.state.selectedLang].dashboard)} tabIndex={0} onKeyUp={e => {
                                                if (e.keyCode === 13) {
                                                    this.tabsClick(this.props.label_keys[this.state.selectedLang].dashboard)
                                                }
                                            }} style={{ cursor: 'pointer' }}>{this.props.label_keys[this.state.selectedLang].myDashboard}</span>
                                            {this.state.title === this.props.label_keys[this.state.selectedLang].dashboard && <hr className={this.state.selectedLang !== 'FR'? "dashboard-underline":"dashboard-underline-fr"} aria-hidden="true" style={{ margin: "4px 0px 0px", borderWidth: "2px" }} />}
                                            
                                        </Col>
                                        {this.props.userDetails.roles.includes("Provisioner") && 
                                        <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4} role="none" span={4} offset={1} style={{ marginRight: this.state.selectedLang === 'FR' ? '-5%' : null }}>
                                            <span role="menuitem" className={this.state.title === this.props.label_keys[this.state.selectedLang].myRequests ? 'active dashboard-none' : 'dashboard-none'}
                                                aria-current={this.state.title === this.props.label_keys[this.state.selectedLang].myRequests ? 'page' : null}   onClick={() => {
                                                    if (this.state.title.includes(this.props.label_keys[this.state.selectedLang].request) && !this.state.title.includes(this.props.label_keys[this.state.selectedLang].myRequests))
                                                        this.setState({ toMyRequest: true })
                                                    this.tabsClick(this.props.label_keys[this.state.selectedLang].myRequests)
                                                }
                                                } tabIndex={0} onKeyUp={e => {
                                                    if (e.keyCode === 13) {
                                                        if (this.state.title.includes(this.props.label_keys[this.state.selectedLang].request) && !this.state.title.includes(this.props.label_keys[this.state.selectedLang].myRequests))
                                                            this.setState({ toMyRequest: true })
                                                        this.tabsClick(this.props.label_keys[this.state.selectedLang].myRequests)
                                                    }
                                                }} style={{ cursor: 'pointer' }}>{this.props.label_keys[this.state.selectedLang].myRequests}</span>
                                            {this.state.title === this.props.label_keys[this.state.selectedLang].myRequests && <hr className={this.state.selectedLang !== 'FR'?"myReq-underline":"myReq-underline-fr"} aria-hidden="true" style={{ margin: "4px 0px 0px", borderWidth: "2px" }} />}
                                            
                                        </Col>}
                                        {this.props.userDetails.roles.includes("Approver") && 
                                        <Col xs={12} sm={12} md={6} lg={this.props.userDetails.roles.includes("Provisioner") && this.state.selectedLang === 'EN' ? 4 :6} xl={this.props.userDetails.roles.includes("Provisioner") && this.state.selectedLang === 'EN' ? 4 : 6} 
                                        xxl={4} role="none"  offset={1} style={{marginRight: this.props.userDetails.roles.includes("Provisioner") && this.state.selectedLang === 'FR' ? '-12%': null}}>
                                            <span role="menuitem" className={this.state.title === this.props.label_keys[this.state.selectedLang].myApprovals ? 'active dashboard-none' : 'dashboard-none'} 
                                            aria-current={this.state.title === this.props.label_keys[this.state.selectedLang].myApprovals ? 'page' : null}
                                            onClick={() => this.tabsClick(this.props.label_keys[this.state.selectedLang].myApprovals)} tabIndex={0} onKeyUp={e => {
                                                if (e.keyCode === 13) {
                                                    this.tabsClick(this.props.label_keys[this.state.selectedLang].myApprovals)
                                                }
                                            }} style={{ cursor: 'pointer' }}>{this.props.label_keys[this.state.selectedLang].myApprovals}</span>
                                            {this.state.title === this.props.label_keys[this.state.selectedLang].myApprovals && <hr className={this.state.selectedLang !== 'FR'?"approver-underline":"approver-underline-fr"} aria-hidden="true" style={{ margin: "4px 0px 0px", borderWidth: "2px" }} />}
                                            
                                        </Col>}
                                        {this.props.userDetails.roles.includes("Provisioner") && 
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} role="none" span={3} offset={1}>
                                            {!this.props.userDetails.viewActiveCardEnable ?
                                                <><span aria-current={this.state.title === this.props.label_keys[this.state.selectedLang].activeCards ? 'page' : null}
                                                    role="menuitem" tabIndex={0} className={this.state.title === this.props.label_keys[this.state.selectedLang].activeCards ? 'active dashboard-none' : 'dashboard-none'}
                                                    onClick={() => { this.tabsClick(this.props.label_keys[this.state.selectedLang].activeCards) }}
                                                    onKeyUp={e => {
                                                        if (e.keyCode === 13) {
                                                            this.tabsClick(this.props.label_keys[this.state.selectedLang].activeCards)
                                                        }
                                                    }}
                                                    style={{ cursor: 'pointer' }}>
                                                    {this.props.label_keys[this.state.selectedLang].activeCards}</span>
                                                    {this.state.title === this.props.label_keys[this.state.selectedLang].activeCards && <hr className={this.state.selectedLang !== 'FR'?"active-underline":"active-underline-fr"} aria-hidden="true"
                                                        style={{ margin: "4px 0px 0px", borderWidth: "2px" }}
                                                    />
                                                    } </>
                                                :
                                                <ActiveCardBtn label_keys={this.props.label_keys[this.state.selectedLang]} onChangeActiveCard={this.onChangeActiveCard} active={this.state.title === this.props.label_keys[this.state.selectedLang].myActiveCards || this.state.title === this.props.label_keys[this.state.selectedLang].allActiveCards}/>
                                            }
                                        </Col>}
                                    </Row>
                                </nav>
                            </Col>
                            <Col xs={16} sm={16} md={4} lg={4} xl={4} xxl={{ span: 4, offset: 1 }}>
                                <div  >
                                    <UserLogoutPopover
                                        label_keys={this.props.label_keys[this.state.selectedLang]}
                                        userDetails={this.props.userDetails}
                                        onClick={this.onLogoutClick}
                                        selectedLang={this.state.selectedLang === 'EN' ? 'English' : 'French'}
                                        focusLangSelect={() => document.getElementById('language-select-button').focus()}
                                    />
                                </div>
                            </Col>
                            <Col xs={{ span: 1, offset: 1 }} sm={{ span: 1, offset: 1 }} md={{ span: 1, offset: 1 }} lg={{ span: 1, offset: 1 }} xl={{ span: 1, offset: 1 }} xxl={{ span: 1, offset: 1 }}>

                                <LanguageSelectBtn onChange={this.languageChange}
                                    value={this.state.selectedLang}
                                    iclanguageList={this.props.iclanguageList}
                                    theme={this.props.theme}
                                    role={this.props.userDetails.roles[0]}
                                />

                            </Col>
                        </Row>
                        {/* <div role="main"> */}
                        <Row>
                            <div className="search-text">
                                {(this.state.title === this.props.label_keys[this.state.selectedLang].bulkCardCreation) && this.state.backBtn === true ?
                                    <a onClick={() => this.handleIsBack(true)} className="custom-back-arrow bulkUpload-back-btn" role="button" tabindex="0"><img src={ArrowBack} alt="Back" /> </a> : ""}
                                {this.state.title !== "All Card History"?<span role="heading" aria-level="1" className="search-text focus-heading"
                                    style={{
                                        marginLeft: (this.state.title.includes(this.props.label_keys[this.state.selectedLang].request) &&
                                            !this.state.title.includes(this.props.label_keys[this.state.selectedLang].myRequests)) ?
                                            "2%" : null
                                    }}
                                >{this.state.title}</span>:<div>All Card History <i>(Last 60 Days)</i></div>}
                            </div>
                        </Row></div>
                        {this.state.title === this.props.label_keys[this.state.selectedLang].dashboard &&
                            <DashboardCardComponent
                                selectedLang={this.state.selectedLang === 'EN' ? 'English' : 'French'}
                                label_keys={this.props.label_keys[this.state.selectedLang]}
                                tabsClick={(title, name) => this.tabsClick(title, name)} />}
                        {this.state.title === this.props.label_keys[this.state.selectedLang].bulkCardCreation &&
                            <BulkUploadComponent
                                selectedLang={this.state.selectedLang === 'EN' ? 'English' : 'French'}
                                isBack={this.state.isBack}
                                handleBackBtn={() => this.handleBackBtn()}
                                handleIsBack={() => this.handleIsBack()}
                                tabsClick={(title) => this.tabsClick(title)}
                                label_keys={this.props.label_keys[this.state.selectedLang]} />}
                        {(this.state.title.includes(this.props.label_keys[this.state.selectedLang].myRequests) || this.state.title.includes(this.props.label_keys[this.state.selectedLang].request)) &&
                            <MyRequestComponent
                                selectedLang={this.state.selectedLang === 'EN' ? 'English' : 'French'}
                                label_keys={this.props.label_keys[this.state.selectedLang]}
                                tabsClick={(title) => this.tabsClick(title)}
                                toMyRequest={this.state.toMyRequest}
                                goToMyRequest={() => this.goToMyRequest()}
                                title={this.state.title}
                                name={this.state.name} />}
                        {this.state.title === this.props.label_keys[this.state.selectedLang].myApprovals &&
                            <MyApproval
                                selectedLang={this.state.selectedLang === 'EN' ? 'English' : 'French'}
                                label_keys={this.props.label_keys[this.state.selectedLang]}
                                name={this.state.name} />}
                        {(this.state.title === this.props.label_keys[this.state.selectedLang].activeCards || this.state.title === this.props.label_keys[this.state.selectedLang].myActiveCards) &&
                            <ActiveCards
                                selectedLang={this.state.selectedLang === 'EN' ? 'English' : 'French'}
                                label_keys={this.props.label_keys[this.state.selectedLang]}
                                title={!this.props.userDetails.viewActiveCardEnable ? this.state.title : this.props.label_keys[this.state.selectedLang].myActiveCards} />}
                        {this.state.title === this.props.label_keys[this.state.selectedLang].allActiveCards &&
                            <AllActiveCards
                                selectedLang={this.state.selectedLang === 'EN' ? 'English' : 'French'}
                                companyId={this.props.userDetails.companyId}
                                label_keys={this.props.label_keys[this.state.selectedLang]} />}
                        {(this.state.title !== this.props.label_keys[this.state.selectedLang].allActiveCards) && (this.state.title !==this.props.label_keys[this.state.selectedLang].activeCards) && (this.state.title !== this.props.label_keys[this.state.selectedLang].myApprovals) && ((this.state.title !== this.props.label_keys[this.state.selectedLang].myActiveCards)) && (this.state.title !== this.props.label_keys[this.state.selectedLang].dashboard) && (this.state.title !== this.props.label_keys[this.state.selectedLang].bulkCardCreation) && (!this.state.title.includes(this.props.label_keys[this.state.selectedLang].myRequests) && !this.state.title.includes(this.props.label_keys[this.state.selectedLang].request)) &&
                             <AllHistoryCards
                                selectedLang={this.state.selectedLang === 'EN' ? 'English' : 'French'}
                                companyId={this.props.userDetails.companyId}
                                label_keys={this.props.label_keys[this.state.selectedLang]} />}
                        {/* </div> */}
                    </div>
                </Content>
            </Layout >
        )
    }
}

const ActiveCardBtn = (props) =>{

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return(
        <>
            <div style={{fontStyle:'U.S.BankCircularWeb-Regular'}}>
                <Button
                    className="active-card-button"
                    id="active-card-button"
                    tabIndex={0} 
                    disableRipple
                    disableElevation
                    role="menuitem"
                    aria-current={props.active ? 'page' : null}
                    aria-controls={open ? 'active-card-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    onClick={handleClick}
                    endIcon={<Icon aria-hidden="true" role="img" style={{ color: 'white', fontSize: 13 }} type="down" />}
                    >
                        <span style={{marginRight: '5px'}}>{props.label_keys.activeCards}</span>
                </Button>
                <Menu
                    id="active-card-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleClose}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'active-card-button',
                    }}
                    PaperProps={{
                        style: {
                            width: '10%',
                        },
                        }}
                    onKeyDown={(event) => {
                        if (event.keyCode === 13) 
                            handleClose()
                        }
                    }
                    // style={{ widt }}
                >
                    <MenuItem  key="0"className="ActiveCard-menuItem"  
                        onClick={props.onChangeActiveCard}>
                    <div >{props.label_keys.myActiveCards}</div>
                    </MenuItem>
                    <MenuItem className="ActiveCard-menuItem" key="1"  onClick={props.onChangeActiveCard}>
                        <div  >{props.label_keys.allActiveCards}</div>
                    </MenuItem>
                    {/* All card histroy tab hidden
                    <MenuItem className="ActiveCard-menuItem" key="2"  onClick={props.onChangeActiveCard}>
                        <div>All Card History</div>
                    </MenuItem> */}
                </Menu>
            </div>
        </>
    )
}

function mapStateToProps(state, props) {
    return {
        userDetails: state.authenticationReducer.userDetails,
        token: state.authenticationReducer.token,
        iclanguageList: state.instantCardMultiLanReducer.iclanguageList,
        label_keys: state.instantCardMultiLanReducer.label_keys,
        theme: state.authenticationReducer.theme
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logOutFun: () => { dispatch(logOut()) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent)
